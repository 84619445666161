<template>
  <base-chart-card color="#2E7D32">
    <template v-slot:heading>
      <base-bar-chart
        v-if="showChart"
        :chart-data="dataCollection"
        :options="options"
        style="max-height: 150px;"
      />
    </template>
    <template v-slot:reveal-actions>
      <v-tooltip bottom>
        <template v-slot:activator="{ attrs, on }">
          <v-btn
            v-bind="attrs"
            color="info"
            icon
            v-on="on"
          >
            <v-icon
              color="info"
            >
              mdi-refresh
            </v-icon>
          </v-btn>
        </template>

        <span>Refresh</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ attrs, on }">
          <v-btn
            v-bind="attrs"
            light
            icon
            v-on="on"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>

        <span>Change Date</span>
      </v-tooltip>
    </template>

    <h3 class="my-card-title-style">
      Daily Check-In Activities
    </h3>
    <p class="my-card-subtitle-style">
      Check-In activities till {{ currentDateTime | convertToLocal }} <br>
      Suspicious check-ins reported today: <font style="color:#C62828;">
        {{ suspiciousActivitiesDaily.length }} Nos.
      </font>
    </p>
    <p
      v-if="noDataExist"
      class="alert-style"
    >
      No Data to Display.
    </p>
    <template v-slot:actions>
      <v-icon
        class="mr-1"
        small
        dense
      >
        mdi-clock-outline
      </v-icon>
      <span class="my-updation-text-style">Updated {{ lastUpdatedAt | diffHumans }}</span>
    </template>
  </base-chart-card>
</template>
<script>
  import moment from 'moment';
  import { isCurrentCheckInsDataExist } from 'src/helpers/chart-data-helper';

  export default {
    name: 'CurrentCheckIns',
    filters: {
      diffHumans (val) {
        return moment(val).fromNow();
      },
      convertToLocal (stringDatetime) {
        return moment(stringDatetime).local().format('Do MMMM YYYY hh:mm A');
      },
    },
    data: () => ({
      label: 'Active Check-Ins',
      currentDateTime: moment(),
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          labels: {
            fontColor: '#FBD6E3',
            fontsize: 14,
          },
        },
        scales: {
          yAxes: [{
            ticks: {
              fontColor: '#FBD6E3',
              stepSize: 50,
            },
          }],
          xAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'My Sites',
              fontColor: '#FBD6E3',
            },
            ticks: {
              display: false,
              fontColor: '#FBD6E3',
            },
          }],
        },
      },
    }),
    computed: {
      chartData () {
        return this.$store.getters['dashboard/getCurrentActiveCheckIns'];
      },
      dataExist () {
        return this.chartData.length > 0;
      },
      showChart () {
        return Object.keys(this.dataCollection).length > 0;
      },
      dataCollection () {
        if (this.dataExist === false) {
          return {};
        }
        return this.generateDataCollection();
      },
      noDataExist () {
        const dataExist = isCurrentCheckInsDataExist(this.chartData);
        if (dataExist === true) {
          return false;
        }
        return true;
      },
      lastUpdatedAt () {
        return this.$store.getters['dashboard/getLastUpdatedAt'];
      },
      suspiciousActivitiesDaily () {
        return this.$store.getters['dashboard/getCurrentSuspiciousActivities'];
      },
    },
    mounted () {
      this.fetchCurrentActiveCheckInsData();
      this.fetchCurrentSuspiciousActivitiesData();
    },
    methods: {
      fetchCurrentActiveCheckInsData () {
        this.$store.dispatch('dashboard/fetchCurrentActiveCheckInsData');
      },
      fetchCurrentSuspiciousActivitiesData () {
        this.$store.dispatch('dashboard/fetchCurrentSuspiciousActivities', {
          params: {
            from: moment().startOf('day').toISOString(),
            to: moment().toISOString(),
          },
        });
      },
      generateDataCollection () {
        const actionCount = [];
        const labels = [];
        for (const result of this.chartData) {
          actionCount.push(result.active_users.length);
          labels.push(result.project.name);
        }
        return {
          labels: labels,
          datasets: [
            {
              data: actionCount,
              label: this.label,
              backgroundColor: '#FBD6E3',
              fill: true,
              barThickness: 20,
            },
          ],
        };
      },
    },
  };
</script>
<style scoped>
.alert-style {
  color: #C62828;
  text-align: center;
}
.my-card-title-style {
  color: #37474F;
  margin-left: 2px;
  margin-top: 10px;
  font-size: 18px;
}
.my-card-subtitle-style {
  color: #37474F;
  margin-left: 2px;
  margin-top: 7px;
  font-size: 14px;
}
.my-updation-text-style {
  color: #37474F;
  font-size: 12px;
}
</style>
