<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="6"
        lg="3"
        xl="3"
      >
        <base-material-stats-card
          color="#00695C"
          icon="mdi-account-group"
          title="App Users"
          :value="appUsers.toString()"
          sub-icon="mdi-clock-outline"
          :sub-text="lastUpdatedAt | diffHumans"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="6"
        lg="3"
        xl="3"
      >
        <base-material-stats-card
          color="#01579B"
          icon="mdi-account-check"
          title="Active Check-Ins"
          :value="activeCheckInsCount.length.toString()"
          sub-icon="mdi-clock-outline"
          :sub-text="lastUpdatedAt | diffHumans"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="6"
        lg="3"
        xl="3"
      >
        <base-material-stats-card
          color="#E65100"
          icon="mdi-account-question"
          title="Suspicious Check-Ins"
          :value="suspiciousCheckIns.length.toString()"
          sub-icon="mdi-clock-outline"
          :sub-text="lastUpdatedAt | diffHumans"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="6"
        lg="3"
        xl="3"
      >
        <base-material-stats-card
          color="#C62828"
          icon="mdi-account-alert"
          title="Least Active Users"
          :value="leastActiveAppUsers.length.toString()"
          sub-icon="mdi-check"
          sub-text="No activities for last quarter."
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
  import moment from 'moment';

  export default {
    name: 'DashBoardStatusCount',
    filters: {
      diffHumans (val) {
        return moment(val).fromNow();
      },
    },
    data () {
      return {};
    },
    computed: {
      lastUpdatedAt () {
        return this.$store.getters['dashboard/getLastUpdatedAt'];
      },
      paginatedAppUsersStructure () {
        return this.$store.getters['appusers/getAppUsers'];
      },
      appUsers () {
        if (Object.keys(this.paginatedAppUsersStructure).length > 0) {
          return this.paginatedAppUsersStructure.total;
        }
        return 0;
      },
      suspiciousCheckIns () {
        return this.$store.getters['widgets/getSuspiciousCheckIns'];
      },
      leastActiveAppUsers () {
        return this.$store.getters['dashboard/getLeastActiveAppUsers'];
      },
      activeCheckInsCount () {
        return this.$store.getters['dashboard/getActiveCheckInsCount'];
      },
    },
    mounted () {
      this.lastUpdated();
      this.getAppUsersList({ itemsPerPage: 10, pageStart: 0 });
      this.getAllSuspiciousCheckIns();
      this.getLeastActiveAppUsers();
      this.getActiveCheckInsCount();
    },
    methods: {
      lastUpdated () {
        this.$store.dispatch('dashboard/lastUpdatedAt', {
          updatedAt: moment().toISOString(),
        });
      },
      async getAppUsersList ({ itemsPerPage, pageStart }) {
        await this.$store.dispatch('appusers/fetchAppUsers', {
          params: {
            params: {
              limit: null,
              startPage: null,
            },
          },
        });
      },
      async getAllSuspiciousCheckIns () {
        await this.$store.dispatch('widgets/fetchSuspiciousCheckIns');
      },
      async getLeastActiveAppUsers () {
        await this.$store.dispatch('dashboard/fetchLeastActiveAppUsers');
      },
      async getActiveCheckInsCount () {
        await this.$store.dispatch('dashboard/fetchActiveCheckInsCount');
      },
    },
  };
</script>
